import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Reducer, {initialState} from './Reducer';
import {StateProvider} from './StateProvider';
import { BrowserRouter } from "react-router-dom";
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import rootReducer from './rootReducer'

const store = createStore(rootReducer); 
    //<StateProvider initialState=
    //{initialState} reducer={Reducer}>
    // </StateProvider>
ReactDOM.render(
    <BrowserRouter>
    <Provider store = {store}>
    <App/>
    </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
